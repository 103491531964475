import React, { useState,useEffect } from 'react'
import Addmember from './Addmember';
import AddAgents from './AddAgents';
import './logic2.css'
import AddSuper from './AddSuper';
import Cookies from 'universal-cookie';
import BankingModel from './BankingModel';
import axios from 'axios';
import ChangeStatus from './ChangeStatus';
import CreditRef from './CreditRef';
import Dashboard from './Dashboard';
import Marqueebox from './Marqueebox';
import TotalBox from './TotalBox';
import FixedFooter from './FixedFooter';
import AddAgent from './AddAgents'
import AgentProfile from "./AgentProfile";
import ClientProfile from "./ClientProfile";
import Bethistory from './Bethistory';
import AddHouse from './AddHouse';


const cookies = new Cookies();

export default function Agents(props) {
  const [Changestatus, setChangestatus] = useState(false);
  const [AddMember, setAddMember] = useState(false);
  const [NewaddAgent, setNewaddAgent] = useState(false);
  const [Creditrefence, setCreditrefence] = useState(false);
  const [agentId, setagentId] = useState('');
  const [currentStatus, setcurrentStatus] = useState('');
  const [agentType, setagentType] = useState('');
  const [lev, setlev] = useState('');
  const [updatedStatus, setupdatedStatus] = useState(true);
  const [userId, setuserId] = useState('');
  const [userLevel, setuserLevel] = useState(null);
  // const [updatedBal, setupdatedBal] = useState(true);
  const [ChangePassword, setChangePassword] = useState(false);
  const [view, setview] = useState(1);
  const [pro, setpro] = useState(1);
  const [search, setsearch] = useState('');
  const [searchBtn, setsearchBtn] = useState(true);
  const [oldVal, setoldVal] = useState('');
  const [aorc, setaorc] = useState('');
const [openHouse, setopenHouse] = useState(false)
const [userid, setuserid] = useState('');
const [levelNo, setlevelNo] = useState('');





  const openHHouse=(open)=>{
    setopenHouse(open)
  }

  useEffect(()=>{    
      var ssid = cookies.get('sid');
      axios.post('https://waveapi.in/api/agent/NewagentInfo',{
       sid:ssid
      })
      .then(result => {
        if(result.status === 200){  
           setuserId(result.data.id);
           setuserLevel(result.data.level);   
          }
        }
       ).catch(e => {
          //setIsError(true);
       });
   },[userLevel]);

 const changeUpdatedStatus = ()=>{

     setupdatedStatus(!updatedStatus);
    }

//  const changeUpdatedBal = ()=>{
     
//   setupdatedBal(!updatedBal);
//     }

 const searchItem = (val) =>{
     setsearch(val);
  }

  const searchBtnClicked = () =>{
      setsearchBtn(!searchBtn);
  }




 
const HandlePopup = (val1,val2,obj)=>{

      if(val1 === 1){
        setChangestatus(val2);
        if(val2 === true){
          setlev(obj.lev);
          setagentId(obj.user);
          setcurrentStatus(obj.userstatus);
          setagentType(obj.type);
        }
      }
      if(val1 === 2){
        setAddMember(val2);
      }
      if(val1 === 3){
        setCreditrefence(val2);
        setoldVal(obj.oldVal);
        setagentId(obj.user);
        setaorc(obj.aorc);
      

      }
      if(val1 === 4){
        setNewaddAgent(val2);
       
     }
             if(val1 === 5){
        setChangePassword(val2);
      
     }
  }

return (
    <React.Fragment>
      {Changestatus && <ChangeStatus changeUpdatedStatus = {changeUpdatedStatus} HandlePopup={HandlePopup} lev = {lev} agentId = {agentId} currentStatus = {currentStatus} agentType = {agentType}/>}
      {AddMember && <Addmember HandlePopup={HandlePopup} changeUpdatedStatus = {changeUpdatedStatus}/>}
      {openHouse && <AddHouse openHHouse={openHHouse}/>}
      {NewaddAgent && <AddAgents AgentLevel = {props.AgentLevel} userLevel={userLevel} HandlePopup={HandlePopup}  changeUpdatedStatus = {changeUpdatedStatus}/>}
      {Creditrefence && <CreditRef aorc = {aorc} HandlePopup={HandlePopup} oldVal = {oldVal} agentId = {agentId} changeUpdatedStatus = {changeUpdatedStatus}/>}  
      <div id="mainWrap" class="main_wrap">
        <Marqueebox/>
        <AddSuper  openHHouse={openHHouse} AgentLevel = {props.AgentLevel} userLevel={userLevel} searchItem = {searchItem} searchBtnClicked = {searchBtnClicked} view={props.view} changeView={props.changeView} popAgent = {props.popAgent}  changeUpdatedStatus = {changeUpdatedStatus} agentStack = {props.agentStack} HandlePopup={HandlePopup} agentId = {agentId}/>
        {props.view === 1 && props.view != 3 && <TotalBox selfBalance={props.selfBalance} totalAvbal ={props.totalAvbal} CE={props.CE} TCB={props.TCB} TAB={props.TAB} RB ={props.RB} userid ={userid} levelNo= {levelNo}  />}
        {props.view === 1 && props.view != 3 && <Dashboard userid ={userid} userLevel = {userLevel} AgentLevel={props.AgentLevel} levelNo= {levelNo}  search = {search} searchBtn = {searchBtn} view={props.view} changeView={props.changeView} changePro = {props.changePro}  agentStack = {props.agentStack} pushAgent = {props.pushAgent} HandlePopup={HandlePopup} updatedStatus = {updatedStatus}/>}
        {props.view === 2 && <AgentProfile agentStack = {props.agentStack}/>}
        {props.view === 3 && <ClientProfile agentStack = {props.agentStack} pro = {props.pro}/>}
        {props.view === 4 && <Bethistory agentStack = {props.agentStack} pro = {props.pro}/>}
      </div> 
      <BankingModel/>
     {/* { props.view != 3 && <FixedFooter/> } */}
    </React.Fragment>
       )
  }