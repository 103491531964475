import React,{useState,useEffect} from 'react'
import Messagepopup from './Messagepopup'
import Cookies from 'universal-cookie';
import axios from 'axios';
import moment from 'moment';
import { toast } from "react-toastify";


export default function Notification() {
    const [updateNewsList, setupdateNewsList] = useState([]);
    const [clickNotification, setclickNotification] = useState(false)
const [updateSuccess, setupdateSuccess] = useState(false)

    const cookies = new Cookies();

    const ClickToOpen=(clickNotification)=>{
        setclickNotification( !clickNotification)
    }


    useEffect(() => {
        var ssid = cookies.get('sid');

        axios.post('https://waveapi.in/api/agent/clientnews', {
          sid: ssid,
        }).then(result => {
            var arr = [];
            //  console.log(result.data);
      result.data.map((item)=>{
        arr.push(item)
      })
     // console.log(arr);
      setupdateNewsList(arr);
        }).catch(e => { }); 
    }, [updateSuccess]);


    const deleteNews = () => {

        var ssid = cookies.get('sid');
        axios.post('https://waveapi.in/api/agent/deletenews', {
            sid: ssid,
       


        }).then(result => {
            if (result.status === 200) {
                toast.success(" Delete successfully.", {
                    position: toast.POSITION.TOP_CENTER,
                });
                changeUpdatedStatus();
            }

            else {
                toast.warn(" Opps, there is  something woring!", {
                    position: toast.POSITION.TOP_CENTER,
                });

            }
        }).catch(e => { });
    }


    const changeUpdatedStatus = ()=>{

        setupdateSuccess(!updateSuccess);
       }


    return (
        <div>
                 {clickNotification  ===true  &&<Messagepopup  changeUpdatedStatus ={changeUpdatedStatus} ClickToOpen={ClickToOpen} />}

<div id="mainWrap" className="main_wrap">
    <div className="marquee-box">
        <h4>News</h4>
        <div className="marquee">
            <div className="js-marquee-wrapper" style={{width: '100000px', transform: 'translateX(1278px)', animation: '34.623s linear 0s infinite normal none running marqueeAnimation-327153'}} /></div>
    </div>
    <div className="total_all">
        <div className="agent_path">
            <ul id="agentPath" className="agent_path-L">
                <ul className="account_pop" id="accountPop">
                    <li id="popTmp"><a /></li>
                </ul>
            </ul>
        </div>
        
        <a className="add_member" onClick={()=>{ClickToOpen(false)}} style={{marginRight:
            '2px', padding: '0px 6px', marginBottom: '12px'}}>
            <img id="addMemberi" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />Add News</a></div>
  {updateNewsList.length > 0 &&
    <table id="resultTable" className="table01 margin-table" style={{display: 'table'}}>
        <tbody>
            <tr>
                <th id="accountTh" width="10%" className="align-L">Date</th>
                <th id="balanceTh" width="30%" className="align-L">News</th>
                <th id="exposureTh" width="30%">Action</th>
                
            </tr>
            {updateNewsList.map(function(item,id){

var Date = moment(item.date).format('ll');
return (

            <tr id={14} main_userid="wb77" style={{display: 'table-row'}}>
                <td id="accountCol" className="align-L">
                <a id="account14" className="ico_account">
                {Date}</a></td>
                <td id="creditRef14" style={{display:'flex'}}className="credit-amount-member ">{item.message}</td>
                <td id="currentExposure14"><i class="fa-solid fa-ban" onClick={()=>{deleteNews()}}></i>
                <i class="fa-solid fa-circle-check" ></i>
                <i class="fas fa-edit" style={{paddingLeft:'10px'}}></i>
                </td>
 
            </tr>
             )})} 
                 </tbody>
    </table>}

    { updateNewsList.length >0 &&
    <div>
        <ul id="pageNumberContent" className="pages">
            <li id="prev"><a className="disable">Previous</a></li>
            <li id="pageNumber"><a className="select" style={{pointerEvents: 'none'}}>1</a> </li>
            <li id="next"><a className="disable">Next</a></li><input type="number" id="goToPageNumber_1" maxLength={6} size={4} /><a id="goPageBtn_1">GO</a></ul>
    </div>}
</div>
           
        </div>
    )
}