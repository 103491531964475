import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import Transparent from './images/transparent.gif'
import Loading from './images/loading40.gif'
import { Link } from 'react-router-dom';

const cookies = new Cookies();


var globalArray1 = [];


export default function Dashboard(props) {

   const [user,setuser] = useState([]);
   const [agentView,setagentView] = useState(1);
   const [refresh,setrefresh] = useState(true);
   const [a,seta] = useState(0);

//console.log(props.agentStack);

            useEffect(()=>{

                var ssid = cookies.get('sid');

                     if(props.agentStack.length > 1)return; 
          
                  
                     globalArray1 = [];
                     setrefresh(true);

                     axios.post('https://waveapi.in/api/agent/newgetDownlineDetaliInfo',{
                        sid:ssid,
                          
                           })
                           .then(result => {
                               setrefresh(false);
                               if(result.status === 200){

                                 for (let key in result.data) {  
                                     if (result.data.hasOwnProperty(key) ) 
                                     { 
                                         result.data[key].user = key;
                                         globalArray1.push(result.data[key]);
                                     //    console.log(result.data[key],'data');  
                                     } 
                                 }
                                 let arr = [];
                                 globalArray1.map((item)=>{
                                     arr.push(item);
                                 });


                                 var startCount = 0;
                    
                                 if(window.changePage == 1){
                                   var startCount = 15*(agentView-1);
                                 }
                                 setuser(arr.splice(startCount,15)); 
                                 if(window.changePage == 1){
                                     window.changePage = 2;
                                 }


                                //  setuser(arr); 
                                 
                               }
                               if(refresh){
                                 setrefresh(false);
                               }
                              }    
                             ).catch(e => {
                               //setIsError(true);
                           });
         
        },[props.updatedStatus]);

        
        useEffect(()=>{
        
            let arr = [];
            globalArray1.map((item)=>{
                arr.push(item);
            });
            var startCount = 15*(agentView-1);
            
            
            setuser(arr.splice(startCount,15)); 
    
    
            },[agentView]);




            useEffect(()=>{
   
                if(props.agentStack.length <= 1)return;
                var agentId = props.agentStack[props.agentStack.length-1].userid;
                var levelId = props.agentStack[props.agentStack.length-1].level;
               
                var ssid = cookies.get('sid');
                if(!ssid) return;
                globalArray1 = [];
                setagentView(1);

        
                setrefresh(true);
                axios.post('https://waveapi.in/api/agent/getDownlineBelowDetaliInfo',{
                       id:agentId,
                     
                      })
                      .then(result => {
                        setrefresh(false);
                          if(result.status === 200){
                             
                            for (let key in result.data) { 
                                
                                if (result.data.hasOwnProperty(key)) 
                                { 
                                    result.data[key].user = key;
                                    globalArray1.push(result.data[key]);  
                                } 
                            }
                            let arr = [];
                            globalArray1.map((item)=>{
                                arr.push(item);
                            });
                            var startCount = 0;
                    
                            if(window.changePage == 1){
                              var startCount = 15*(agentView-1);
                            }
                            setuser(arr.splice(startCount,15)); 
                            if(window.changePage == 1){
                                window.changePage = 2;
                            }
                          }
                 
                         }
                             
                        ).catch(e => {
                          //setIsError(true);
                    });
              },[props.agentStack.length]);
    
      useEffect(()=>{
        
        var arr1 = globalArray1.filter(function(item) {
            let sub1 = item.user.substring(0,props.search.length);
            return sub1.toUpperCase() === props.search.toUpperCase();
        })
        
        if(props.search === ''){
            setuser(arr1.splice(0,15));  
            setagentView(1);
            return;
        }
        setuser(arr1.splice(0,14));         
        },[props.searchBtn]);
        

        const changeCountAgent = (e)=>{
            seta(parseInt(e.target.value));
        }




    return (
    <React.Fragment>

     {refresh && 

<div className="loading-overlay" id="loading"><div className="loading-wrap" style={{display: 'flex'}}><div className="loading"><div /><div /></div><p>Loading...</p></div></div>
          
          }
{/* agent */}
{  globalArray1.length > 0 && globalArray1 != undefined && <table id="resultTable" class="table01 margin-table" style={{display:' table'}}>
            <tbody>
            <tr>
                <th id="accountTh" width="10%" class="align-L" >Account</th>
                {/* <th id="creditRefTh"  width="5%" >Credit Ref.</th>  */}
               
                <th id="balanceTh" width="10%" >Credit Ref.</th>


                {props.userLevel  === 5 &&
                <th id="balanceTh" width="10%" >Balance</th>}
                {props.userLevel  < 5 &&  props.agentStack[props.agentStack.length-1].level < 5 &&
                <th id="exposureTh" width="10%" >Balance</th>}
                
                    
                {props.userLevel  < 5 && props.agentStack[props.agentStack.length-1].level < 5 &&
                
                <th id="playerBalanceTh" width="10%" >Exposure</th>}

                     {props.userLevel  < 5 && props.agentStack[props.agentStack.length-1].level < 5 &&
                <th id="availableBalanceTh" width="10%" >Avail Bal.</th>}
               

               {props.userLevel != 5 && props.agentStack[props.agentStack.length-1].level == 5 &&

               <th id="availableBalanceTh" width="10%" >Balance</th>}

               {props.userLevel === 5 &&
                <th id="playerBalanceTh" width="10%" >Net Exposure</th>}

               { props.userLevel  === 5 &&
                <th id="availableBalanceTh" width="10%" >Available bal.</th>}
              
              { props.userLevel != 5 && props.agentStack[props.agentStack.length-1].level ==5 &&
                <th id="playerBalanceTh" width="10%" >Available bal.</th>}

                {props.userLevel != 5 && props.agentStack[props.agentStack.length-1].level ==5 &&
                <th id="playerBalanceTh" width="10%" >Net Exposure</th>}

                {/* {props.userLevel  < 4 && props.agentStack[props.agentStack.length-1].level <4 &&
                <th id="exposureLimitTh" width="10%" style={{}}>Total Agent Bal.</th>} */}

                {props.userLevel  < 5 && props.agentStack[props.agentStack.length-1].level <5 &&
                <th id="exposureLimitTh" width="10%" style={{}}>Player Balance</th>}

                {/* { props.userLevel  < 5 &&   props.agentStack[props.agentStack.length-1].level <5 && */}
                {/* <th id="refPLTh2" width="10%" >Downline. P/L</th> */}
                {/* } */}
              
                <th id="refPLTh" width="10%" >Ref. P/L</th>
              {/* { props.userLevel  === 5 &&
                <th id="refPLTh" width="10%" >Ref. P/L</th>} */}

                <th id="statusTh" width="10%" >Status</th>
                <th id="actionTh" width="13%" >Action</th>
            </tr>
            <tr id="dataTemplate" style={{display:'none'}}>
                <td id="accountCol"  class="align-L">
                    
                    <a id="account" class="ico_account"></a>
                </td>
                <td id="creditRef" class="credit-amount-member" ><a id="creditRefBtn" class="favor-set" ></a></td>
                <td id="creditLimit" style={{display:'none'}}></td>
                
                <td id="currentPL" style={{display:'none'}}></td>
                <td id="transferablePL" style={{display:'none'}}></td>
                <td id="balance" ></td>
                <td id="currentExposure" ></td>
                <td id="available" ></td>
                <td id="creditPL" style={{display:'none'}}></td>
                <td id="playerBalance" style={{display:'none'}}></td>
                <td id="exposureLimit" style={{display:'none'}}></td>
            
                <td id="refPL" ></td>
                <td id="statusCol" >
                    <ul id="tipsPopup" class="status-popup" style={{display:'none'}}>
                    </ul>
                    <span id="status"></span>
                </td>
                <td id="actionCol" >
                    <ul class="action">
                        <li>
                            <a id="banking" class="bank">Bank</a>
                        </li>
                        <li>
                            <a id="p_l" class="p_l" >Betting Profit &amp; Loss</a>
                        </li>
                        <li>
                            <a id="betting_history" class="betting_history">Betting History</a>
                        </li>
                        <li>
                            <a id="change" class="status" href="#change">Change Status</a>
                        </li>
                        <li>
                            <a id="profile" class="profile">Profile</a>
                        </li>
                    </ul>
                </td>
            </tr>

            {user.map((item,index)=>{
        var lev;
        var cla;

        if(item.level == 0){
            lev = 'ML';
            cla = '1';
          }
        if(item.level == 1){
            lev = 'WL';
            cla = '1';
          }
        else if(item.level == 2){
           lev = 'SSS';
           cla = '2';
          }
         else if(item.level == 3){
           lev = 'SS';
           cla = '3';
          }
         else if(item.level == 4){
           lev = 'SUP';
           cla = '4';
          }
          else if(item.level == 5){
            lev = 'MA';
            cla = '5';
           }
          
           let ld = parseFloat(item.Distributed)  * (-1);;
           let avail_balance = item.Balance;
           let b = parseFloat(item.FixedLimit)  * (-1);
           var  expo =item.Exposure;
           var CreditLimit = parseFloat(item.Distributed) * (-1) + parseFloat(item.AvilableCredit)
        //   let b = item.fix ? item.balance : 0;
        //   let ld = item.ldb ? item.ldb : 0;

        let  lds = parseFloat(item.AvilableCredit);
        var totaldepo = parseFloat(item.totaldeposite);
        var  settlement =lds-totaldepo;
       var  BalanceTotal =item.AvlBalance;
          let lw = item.lwc ? item.lwc : 0;
          let le = item.le ? item.le : 0;
        //   var avail_balance = parseFloat(b) - parseFloat(ld) ;
          var TotalcreditLimit = parseFloat(b) + parseFloat(ld) ;
          var status = 1;
        
         var playerBalance = item.PlayerBalance;
         var AgentBal = parseFloat(item.AvlBalance) + parseFloat(expo);
         var clientBal = parseFloat(item.Balance) + parseFloat(expo);
          let cr = BalanceTotal - parseFloat(item.cfBalance);
          let crMast = item.Balance  - parseFloat(item.cfBalance);
          if(item.userBlocked == 0 && item.betBlocked == 0){
              status = 1;
          }
          else if(item.userBlocked == 0 && item.betBlocked == 1){
              status = 2;
          }
          else if(item.userBlocked == 1){
              status = 3;
          }
       
         
        return(
      
      <tr key={index} id="14" style={{display: 'table-row'}} main_userid="wb77">
      {props.userLevel  < 5 && props.agentStack[props.agentStack.length-1].level <5 &&
                <td id="accountCol"  class="align-L">
                    
                <a onClick ={()=>{if(props.agentStack.length > 0 && props.agentStack[props.agentStack.length-1].level <=5){props.pushAgent(item.user,cla);}}} id="account14" class="ico_account"><span className={`lv_${true ? cla:" "}`}>{lev}</span>{item.user}</a>
                </td>}
                
                {props.userLevel  === 5 &&
                <td id="accountCol"  class="align-L">
                    <a style = {{pointerEvents:'none'}} id="account14" class="ico_account"><span class="lv_0">PL</span>{item.user}</a>
                </td>
                }

       {props.userLevel  != 5 && props.agentStack[props.agentStack.length-1].level == 5 &&
                <td id="accountCol"  class="align-L">
                    <a style = {{pointerEvents:'none'}} id="account14" class="ico_account"><span class="lv_0">PL</span>{item.user}</a>
                </td>
                }
                { props.userLevel  < 5 && props.agentStack.length === 1 && <td id="creditRef14"  class="credit-amount-member" ><a id="creditRefBtn" class="favor-set" onClick = {()=>{props.HandlePopup(3,true,{user:item.user,oldVal:item.cf,aorc:'1'})}}>{parseFloat(item.cfBalance).toFixed(2)}</a></td>}
                
                { props.userLevel  == 5 && props.agentStack.length === 1 && <td id="creditRef14"  class="credit-amount-member" ><a id="creditRefBtn" class="favor-set" onClick = {()=>{props.HandlePopup(3,true,{user:item.user,oldVal:item.cf,aorc:'2'})}}>{parseFloat(item.cfBalance).toFixed(2)}</a></td>}
                
               {props.agentStack.length > 1 && <td id="creditRef14"  class="credit-amount-member" >{parseFloat(item.cfBalance).toFixed(2)}</td>}

               {/* {props.userLevel  < 5 && props.agentStack[props.agentStack.length-1].level <5 &&
                <td id="balance14" >{parseFloat(CreditLimit).toFixed(2)}</td>} */}
              

              
     
               { props.userLevel  === 5 &&
               <td id="balance14" >{parseFloat(item.Balance).toFixed(2)}</td>}


                {props.userLevel  < 5 && props.agentStack[props.agentStack.length-1].level <5 &&
                                <td id="currentExposure14" >{parseFloat(BalanceTotal).toFixed(2)}</td>
                                }
              
              
              {props.userLevel  != 5 && props.agentStack[props.agentStack.length-1].level == 5 &&
               <td id="balance14" >{parseFloat(item.Balance).toFixed(2)}</td>}


                {props.userLevel  != 5 && props.agentStack[props.agentStack.length-1].level == 5 &&
                               <td id="available14" >{parseFloat(clientBal).toFixed(2)}</td>
                                }


                                {props.userLevel  === 5 &&
                <td id="playerBalance14"  className={`${(expo >= 0) ? "green": "red"}`} style={{display: 'table-cell'}}>{'('+parseFloat(Math.abs(expo)).toFixed(2)+')'}</td>}

              {props.userLevel  < 5 &&   props.agentStack[props.agentStack.length-1].level <5 &&
                <td id="playerBalance14"  className={`${(expo >= 0) ? "green": "red"}`} style={{display: 'table-cell'}}>{'('+parseFloat(Math.abs(expo)).toFixed(2)+')'}</td>}

{props.userLevel  < 5 && props.agentStack[props.agentStack.length-1].level <5 &&
                <td id="available14" >{parseFloat(AgentBal).toFixed(2)}</td>}


{props.userLevel ==5 &&
                <td id="available14" >{parseFloat(clientBal).toFixed(2)}</td>}
                {/* { props.userLevel  < 4 && props.agentStack[props.agentStack.length-1].level <4 &&
                <td id="exposureLimit" >{parseFloat(item.totalagentbalance).toFixed(2)}</td>} */}

                {props.userLevel  < 5 && props.agentStack[props.agentStack.length-1].level <5 &&
                <td id="exposureLimit" >{parseFloat(playerBalance).toFixed(2)}</td>}

{ props.userLevel != 5 &&  props.agentStack[props.agentStack.length-1].level == 5 &&
 <td id="playerBalance14"  className={`${(expo >= 0) ? "green": "red"}`} style={{display: 'table-cell'}}>{'('+parseFloat(Math.abs(expo)).toFixed(2)+')'}</td> }
          
            { props.userLevel  === 5 &&
               <td id="userReferencePL" className={`${(crMast >= 0) ? "": "red"}`}>{crMast >= 0 ? parseFloat(Math.abs(crMast)).toFixed(2): '('+parseFloat(Math.abs(crMast)).toFixed(2)+')'}</td>
              } 

{ props.userLevel != 5 &&  props.agentStack[props.agentStack.length-1].level == 5 &&
               <td id="userReferencePL" className={`${(crMast >= 0) ? "": "red"}`}>{crMast >= 0 ? parseFloat(Math.abs(crMast)).toFixed(2): '('+parseFloat(Math.abs(crMast)).toFixed(2)+')'}</td>
              } 

      {props.userLevel  < 5 && props.agentStack[props.agentStack.length-1].level <5 &&
<td id="userReferencePL" className={`${(cr >= 0) ? "": "red"}`}>{cr >= 0 ? parseFloat(Math.abs(cr)).toFixed(2): '('+parseFloat(Math.abs(cr)).toFixed(2)+')'}</td>
            }
{/* {props.userLevel  < 5 &&   props.agentStack[props.agentStack.length-1].level <5 && */}
{/* <td id="refPL14" className={`${(downlinePL >= 0) ? "green": "red"}`}>{downlinePL >= 0 ? parseFloat(Math.abs(downlinePL)).toFixed(2): '('+parseFloat(Math.abs(downlinePL)).toFixed(2)+')'}</td> */}
                {/* } */}
                <td id="statusCol" >
                    <ul id="tipsPopup" class="status-popup" style={{display:'none'}}>
                    </ul>

                   {item.userBlocked == 0 && item.betBlocked == 0 && <span id="status14"  class="status-active"><img src={Transparent}/>Active</span>}
                   {item.userBlocked == 0 && item.betBlocked == 1 && <span id="status14"  class="status-suspend"><img src={Transparent}/>Suspended</span>}
                   {item.userBlocked == 1 && <span id="status14"  class="status-lock"><img src={Transparent}/>Locked</span>}
                </td>
             
                {props.userLevel  < 5 && props.agentStack[props.agentStack.length-1].level <5 &&
                <td id="actionCol" >
                    <ul class="action">
                        <li>
                            <a id="banking" class="bank" style={{display:'none'}}>Bank</a>
                        </li>
                        <li>
                            
                        </li>
                        <li>
                            
                        </li>
                        { props.agentStack.length === 1 && <li>
                            <a id="change14" class="status"  onClick={()=>{window.changePage = 1; props.HandlePopup(1,true,{lev:lev,user:item.user,userstatus:status,type:'ag'})}}>Change Status</a>
                        </li>}
                        <li>
                            <a id="profile14" onClick={()=>{if(props.agentStack.length > 0 && props.agentStack[props.agentStack.length-1].level <5){props.pushAgent(item.user,cla);} props.changeView(2);}} class="profile">Profile</a>
                        </li>
                    </ul>
                 </td>}


                 { props.userLevel != 5 &&  props.agentStack[props.agentStack.length-1].level == 5 &&
              <td id="actionCol">
              <ul class="action">
                      <li>
                          <a id="banking" class="bank"  style={{display:'none'}}>Bank</a>
                      </li>
                      
                     { props.agentStack.length === 1 &&  <li>
                          <a id="change14" class="status"  onClick={()=>{window.changePage = 1; props.HandlePopup(1,true,{lev:'CLI',user:item.user,userstatus:status,type:'cli'})}}>Change Status</a>
                      </li>}

                      <li>
                          <Link to='/clientProfile' id="profile14" onClick={()=>{props.changePro(1); props.pushAgent(item.user,'6'); props.changeView(3);}} class="profile">Profile</Link>
                      </li>
                      
                      <li>
                          <a id="p_l0" onClick={()=>{props.changePro(2);props.pushAgent(item.user,'6'); props.changeView(3);}} class="p_l" >Betting Profit &amp; Loss</a>
                      </li>
                      <li>
                          <a id="betting_history0" onClick={()=>{props.changePro(3); props.pushAgent(item.user,'6'); props.changeView(3);}} class="betting_history">Betting History</a>
                      </li>
                  </ul>
               </td>
            }

         { props.userLevel === 5 &&

                 <td id="actionCol">
                <ul class="action">
                        <li>
                            <a id="banking" class="bank"  style={{display:'none'}}>Bank</a>
                        </li>
                        
                       { props.agentStack.length === 1 &&  <li>
                            <a id="change14" class="status"  onClick={()=>{window.changePage = 1; props.HandlePopup(1,true,{lev:'CLI',user:item.user,userstatus:status,type:'cli'})}}>Change Status</a>
                        </li>}

                        <li>
                            <a id="profile14" onClick={()=>{props.changePro(1); props.pushAgent(item.user,'6'); props.changeView(3);}} class="profile">Profile</a>
                        </li>
                        
                        <li>
                            <a id="p_l0" onClick={()=>{props.changePro(2);props.pushAgent(item.user,'6'); props.changeView(3);}} class="p_l" >Betting Profit &amp; Loss</a>
                        </li>
                        <li>
                            <a id="betting_history0" onClick={()=>{props.changePro(3); props.pushAgent(item.user,'6'); props.changeView(3);}} class="betting_history">Betting History</a>
                        </li>
                    </ul>
                 </td>
                 }
               </tr>
             )})}
            </tbody>
            </table>
            }

  {globalArray1.length > 0 &&  <div>      
            <ul id="pageNumberContent" class="pages">
                <li id="prev" onClick = {()=>{if(agentView > 1){setagentView(agentView-1);}}}><a   className={`${(agentView > 1) ? "": "disable"}`}>Previous</a></li>
                <li id="pageNumber"><a  class="select" style={{pointerEvents: 'none'}}>{agentView}</a> </li>
                <li id="next" onClick = {()=>{if(user.length >= 15){setagentView(agentView+1);}}} ><a  className={`${(user.length >= 15) ? "": "disable"}`}>Next</a></li>
                <input onChange= {(e)=>{changeCountAgent(e);}} type="number" id="goToPageNumber_1"  maxLength="6" size="4"/><a onClick = {()=>{if(a > 0 && a <= (parseInt(globalArray1.length/15)+1)) setagentView(a);}} id="goPageBtn_1">GO</a> 
                </ul>
            </div>}
        {globalArray1.length > 0 && user.length === 0 && <p class="no-data">There are no agents.</p>} 

{/* client */}
        </React.Fragment>
       )
     }
