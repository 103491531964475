import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import Transparent from './images/transparent.gif'
import Pagination from 'react-js-pagination';
import Loading from './images/loading40.gif'

const cookies = new Cookies();



export default function DeclaredFancy(props) {
	const [allLog, setallLog] = useState([]);
	const [refresh,setrefresh] = useState(true);
    
    
   

    useEffect(() => {
        var ssid = cookies.get('sid');
        if(!ssid) return;
        axios.post('https://waveapi.in/api/agent/newagentDeclaredFancy',{
        sid:ssid,
        eventId:props.eventId,
        
			  
           }).then(result => {
            setrefresh(false); 
            if(result.status === 200){
                
              var res = [];
              for (let key in result.data) { 
                    
                 if (result.data.hasOwnProperty(key)) 
                 { 
                    result.data[key].selectionId = key;
                    res.push(result.data[key]);
                    
                         
                 } 
              }

              setallLog(res);
              
            }

            
            
     }
    ).catch(e => {
     //setIsError(true);
     });   
    }, [])


  


return (

<div id="agentlog" class="pop_bg" style={{top:'0',display: 'block'}}>  
{/*<body class="betbuzz365">*/}
<div class="log-wrap" style={{marginTop:'9vh',overflow: 'auto'}}>
	<table class="game-team">
		<tbody><tr>
			<td class="game-name">
				{props.marketname}
				
				<a onClick = {()=>{props.changeDownlineLive();}} style={{top:'-1px'}} class="pop-close" ><img class="icon-back" src={Transparent}/>Close</a>
			</td>
		</tr>
	 </tbody>
 </table>

	

 {refresh && 

<div className="loading-overlay" id="loading"><div className="loading-wrap" style={{display: 'flex'}}><div className="loading"><div /><div /></div><p>Loading...</p></div></div>
          
          }


<div class="pop-content">
<div class="pop-title">
	
		


<div>
	
	<ul id="path_tip" class="agent-path" style={{display:'none'}}>
		<li class="point-agent-path"></li>
		<li style={{display:'none'}}><span class="lv_1">One</span><span id="agent_0"></span></li>
		<li style={{display:'none'}}><span class="lv_2">SS</span><span id="agent_1"></span></li>
		<li style={{display:'none'}}><span class="lv_3">SUP</span><span id="agent_2"></span></li>
		<li style={{display:'none'}} class="last-lv"><span class="lv_4">MA</span><span id="agent_3"></span></li>
	</ul>
</div>


		<table style={{display:'none'}}>

			<tbody><tr id="tempTr">
				<td class="td-UID">
					<span class="order" id="number"></span>
					<a id="userId">
						<strong></strong>
					</a>
				</td>
				<td id="selection_exposure_1">-</td>
				<td id="selection_exposure_3" style={{display:'none'}}>-</td>
				<td id="selection_exposure_2">-</td>
			</tr>

			<tr class="total" id="tempTotalTr">
				<td class="align-L">Total</td>
				<td id="total_selection_exposure_1"></td>
				<td id="total_selection_exposure_3" style={{display:'none'}}></td>
				<td id="total_selection_exposure_2"></td>
			</tr>

			<tr id="tempTr_noData">
				<td class="no-data" colspan="4">
					<p>No Data
					</p>
				</td>
			</tr>
		 </tbody>
        </table>

		<table class="table01">
			<tbody><tr>
				<th class="align-L" width="" rowspan="2">Fancy</th>
				<th class="align-C border-l bgcolor-sub" width="100px" colspan="1">P/L</th>
                <th class="align-C border-l bgcolor-sub" width="100px" colspan="1">Results</th>
			</tr>
			

			</tbody>
      <tbody id="content">

    {allLog.map((item,index)=>{

      return(
        
        <tr key = {index} id="tempTr">
				<td class="td-UID">
					<span class="order" id="number">{item.runnerName}</span>
					
				</td>
				<td id="selection_exposure_1"><span className={`${item.position >= 0 ? "":"red"}`}>{item.position >= 0 ? parseFloat(item.position).toFixed(2) : '('+parseFloat(Math.abs(item.position)).toFixed(2) +')'}</span></td>
				<td id="selection_exposure_2" >{item.result}</td>
			 </tr>
          )})}

       
      
         </tbody>
		</table>
	   </div>
     </div>    

    </div>


    {/*</body>*/}

    </div>
   )
 }