import React, { useState,useEffect } from 'react'


export default function AddHouse(props) {

return (
 <React.Fragment>
  <div id="createModal"  class="pop_bg" style={{top:'0', display:'block'}}>
  <div class="pop_box ">
    <a class="close_pop" >close_pop</a>

    <h3>Add House
    </h3>
    <ul class="half_box add-member-box">
      <li class="add_account_box">
        <dl class="border_b">

          <dt>Username</dt>
          <dd>
            <input id="userName" type="text"  placeholder="Enter" maxLength="16"/>
            <span class="must">＊</span>
            <span id="userNameErrorText" class="error-text" ></span>
          </dd>
          <dt>Password</dt>
          <dd>
            <input  id="userPassword" type="password" placeholder="Enter"/>
            <span class="must">＊</span>
            <span id="passwordErrorText" class="error-text"></span>
          </dd>
          <dt>Confirm Password</dt>
          <dd>
            <input  id="repeatPassword" type="password"  placeholder="Enter"/>
            <span class="must">＊</span>
            <span id="repeatPasswordErrorText" class="error-text"></span>
          </dd>
        </dl>
      </li>
	<li class="pt_allowed_box" style={{display:'none'}}>
      </li>
    </ul>
    <div class="btn_box">
      <a  id="createBtn"  class="btn-send">Create</a>
    </div>
   </div>
   </div>
        </React.Fragment>
    )
}
