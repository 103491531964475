import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import Transparent from './images/transparent.gif'
import Pagination from 'react-js-pagination';
import Loading from './images/loading40.gif'

const cookies = new Cookies();

var sum_A = 0, sum_B = 0, sum_tie = 0;

export default function MarketView(props) {
	const [allLog, setallLog] = useState([]);
	const [refresh,setrefresh] = useState(true);
    const [showDownline,setshowDownline] = useState(false);
    
    useEffect(() => {
      sum_A = 0;
      sum_B = 0; 
      sum_tie = 0;
    }, []);
    

    useEffect(() => {
    console.log('get');
		//if(props.agentStack.length <= 1)return;
		var agentId = props.agentStack[props.agentStack.length-1].userid;
		var levelId = props.agentStack[props.agentStack.length-1].level;

        // var ssid = cookies.get('sid');
        // if(!ssid) return;
        axios.post('https://waveapi.in/api/agent/newbelowagentMarketBelowExposure',{
        id:agentId,
        eventId:props.eventId,
        marketId:props.marketId,
			  
           }).then(result => {
			setrefresh(false);
            if(result.status === 200){
              var res = [];
             
              for (let key in result.data) { 
                    
                 if (result.data.hasOwnProperty(key)) 
                 { 
                     result.data[key].user = key;
                     res.push(result.data[key]);
                     sum_A = sum_A + result.data[key].teamA_total;
                     sum_B = sum_B + result.data[key].teamB_total;
                     sum_tie = sum_tie + result.data[key].draw_total;    
                 } 
              }
              setallLog(res);
            }
     }
    ).catch(e => {
     //setIsError(true);
     });   
    }, [props.agentStack.length,props.changeUpdatedStatus])

	console.log(props.agentStack[props.agentStack.length-1].userid);

return (
<div id="agentlog" class="pop_bg" style={{top:'0',display: 'block'}}>  
{/*<body class="betbuzz365">*/}
<div class="log-wrap" style={{marginTop:'9vh',  overflow:'auto'}}>
	<table class="game-team">
		<tbody><tr>
			<td class="game-name">
				{props.marketname}
				<a onClick = {()=>{props.changeDownlineLive();}} style={{top:'-1px'}} class="pop-close" ><img class="icon-back" src={Transparent}/>Close</a>
			</td>
		</tr>
	 </tbody>
 </table>


 {allLog.length &&  <div class="agent_path">
 <ul id="agentPath" class="agent_path-L">

{props.agentStack.map((item,index)=>{
	console.log(item.level);
  var levelname;
  if(item.level == '0'){
      levelname = 'ML';
  }
  else if(item.level == '1'){
      levelname = 'WL';
  }
  else if(item.level == '2'){
      levelname = 'SSS';
  }
  else if(item.level == '3'){
	levelname = 'SS';
}
else if(item.level == '4'){
	levelname = 'SUP';
}
else if(item.level == '5'){
	levelname = 'MA';
}
///if(props.agentStack.length < 2) return;
return( 
  <li key = {index} id="path5" className={`${(index == props.agentStack.length-1) ? "last_li": ""}`}>
  <a onClick = {()=>{if(index == props.agentStack.length-1){return;} if(index === 0){props.changeUpdatedStatus();} props.popAgent(index);if(props.view === 2 || props.view === 3){props.changeView(1);}}} >
	 <span className={`lv_${item.level !== '6' ? item.level:"0"}`}>
	   {levelname}
	  </span>
	  <strong>{item.userid}</strong>
	 </a>
   </li>

  )})}
     </ul>
   </div>}

 {refresh && 
<div className="loading-overlay" id="loading"><div className="loading-wrap" style={{display: 'flex'}}><div className="loading"><div /><div /></div><p>Loading...</p></div></div>
          
          }
<div class="pop-content">
<div class="pop-title">
<div>
	<ul id="path_tip" class="agent-path" style={{display:'none'}}>
		<li class="point-agent-path"></li>
		<li style={{display:'none'}}><span class="lv_1">One</span><span id="agent_0"></span></li>
		<li style={{display:'none'}}><span class="lv_2">SS</span><span id="agent_1"></span></li>
		<li style={{display:'none'}}><span class="lv_3">SUP</span><span id="agent_2"></span></li>
		<li style={{display:'none'}} class="last-lv"><span class="lv_4">MA</span><span id="agent_3"></span></li>
	</ul>
</div>


		<table style={{display:'none'}}>

			<tbody><tr id="tempTr">
				<td class="td-UID">
					<span class="order" id="number"></span>
					<a id="userId">
						<strong></strong>
					</a>
				</td>
				<td id="selection_exposure_1">-</td>
				<td id="selection_exposure_3" style={{display:'none'}}>-</td>
				<td id="selection_exposure_2">-</td>
			</tr>

			<tr class="total" id="tempTotalTr">
				<td class="align-L">Total</td>
				<td id="total_selection_exposure_1"></td>
				<td id="total_selection_exposure_3" style={{display:'none'}}></td>
				<td id="total_selection_exposure_2"></td>
			</tr>

			<tr id="tempTr_noData">
				<td class="no-data" colspan="4">
					<p>No Data
					</p>
				</td>
			</tr>
		</tbody></table>

		<table class="table01">
			<tbody><tr>
				<th class="align-L" width="" rowspan="2">Downline</th>
				<th class="align-C border-l bgcolor-sub" width="" colspan="3">Player P/L</th>
			</tr>
			<tr>
				<th class="border-l bgcolor-sub" width="7%">1</th>
			<th class="border-l bgcolor-sub" width="7%" style={{}}>X</th>
				<th class="border-l bgcolor-sub" width="7%">2</th>
			</tr>

			</tbody>
      <tbody id="content">

    {allLog.map((item,index)=>{
      var lev;
	  var cla;

	  if(item.level == 0){
		  lev = 'ML';
		  cla = '1';
		}
	  if(item.level == 1){
		  lev = 'WL';
		  cla = '1';
		}
	  else if(item.level == 2){
		 lev = 'SSS';
		 cla = '2';
		}
	   else if(item.level == 3){
		 lev = 'SS';
		 cla = '3';
		}
	   else if(item.level == 4){
		 lev = 'SUP';
		 cla = '4';
		}
		else if(item.level == 5){
		  lev = 'MA';
		  cla = '5';
		 }
      return(
        
        <tr key = {index} id="tempTr">
				<td class="td-UID">
					<span class="order" id="number">{index + 1}.</span>
					<a id="userId" onClick ={()=>{if(props.agentStack.length > 0){props.pushAgent(item.user,cla);}}}>
				{ item.level <= 5 &&
					<span className={`lv_${true ? cla:" "}`}>{lev}</span>}
						{ item.first_name &&
					<span className={`lv_0`}>PL</span>}
					
						<strong>{item.user}</strong>
					</a>
				</td>
				<td id="selection_exposure_1"><span className={`${item.teamA_total >= 0 ? "":"red"}`}>{item.teamA_total >= 0 ? parseFloat(item.teamA_total).toFixed(2) : '('+parseFloat(Math.abs(item.teamA_total)).toFixed(2) +')'}</span></td>
				<td id="selection_exposure_3" className={`${item.draw_total >= 0 ? "":"red"}`}>{item.draw_total >= 0 ? parseFloat(item.draw_total).toFixed(2) : '('+parseFloat(Math.abs(item.draw_total)).toFixed(2) +')'}</td>
				<td id="selection_exposure_2" className={`${item.teamB_total >= 0 ? "":"red"}`}>{item.teamB_total >= 0 ? parseFloat(item.teamB_total).toFixed(2) : '('+parseFloat(Math.abs(item.teamB_total)).toFixed(2) +')'}</td>
			 </tr>
       )})}

       <tr class="total" id="tempTotalTr">
				<td class="align-L">Total</td>
				<td id="total_selection_exposure_1"><span className={`${sum_A >= 0 ? "":"red"}`}>{sum_A >= 0 ? parseFloat(sum_A).toFixed(2) : '('+parseFloat(Math.abs(sum_A)).toFixed(2) +')'}</span></td>
        <td id="total_selection_exposure_3" className={`${sum_tie >= 0 ? "":"red"}`}>{sum_tie >= 0 ? parseFloat(sum_tie).toFixed(2) : '('+parseFloat(Math.abs(sum_tie)).toFixed(2) +')'}</td>
				<td id="total_selection_exposure_2" className={`${sum_B >= 0 ? "":"red"}`}>{sum_B >= 0 ? parseFloat(sum_B).toFixed(2) : '('+parseFloat(Math.abs(sum_B)).toFixed(2) +')'}</td>
			</tr>
      
      
      </tbody>
		</table>
	 </div>
   

  </div>    

   </div>


     {/*</body>*/}

    </div>
   )
 }