import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import Transparent from './images/transparent.gif'
import Pagination from 'react-js-pagination';
import Loading from './images/loading40.gif'

const cookies = new Cookies();

export default function AgentLog(props) {
    const [allLog, setallLog] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(50);
    const [refresh,setrefresh] = useState(true);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = allLog.slice(indexOfFirstPost, indexOfLastPost);

    useEffect(() => {
        var ssid = cookies.get('sid');
        if(!ssid) return;
        axios.post('https://waveapi.in/api/agent/newclientTransHistory',{
			  sid:ssid,
			  agentId:props.selectedAgent
           }).then(result => {
            setrefresh(false);   
            var arr = [];
            // console.log(result.data);
            result.data.map((item)=>{
              if(item.fromAgent && item.fromAgent.toUpperCase()){
                arr.push(item);
            }
            })
            setallLog(arr);
            
            
     }
    ).catch(e => {
     //setIsError(true);
     });   
    }, [])


   const handlePageChange = ( pageNumber ) => {
      setCurrentPage( pageNumber )
    };



return (

<div id="agentlog" class="pop_bg" style={{top:'0',display: 'block'}}>  
{/*<body class="betbuzz365">*/}
<div class="log-wrap" style={{marginTop:'9vh', width: "1280px",overflow:'auto'}}>
	<table class="game-team">
		<tbody><tr>
			<td class="game-name">
				Banking Logs
				<span style={{right:'90px'}} id="title_userId" >{props.selectedAgent}</span>
				<a onClick = {()=>{props.changePlayerLog();}} style={{top:'-1px'}} class="pop-close" ><img class="icon-back" src={Transparent}/>Close</a>
			</td>
		</tr>
	</tbody>
</table>

	

{refresh && 

<div className="loading-overlay" id="loading"><div className="loading-wrap" style={{display: 'flex'}}><div className="loading"><div /><div /></div><p>Loading...</p></div></div>
          
          }



<div id="message" class="message-wrap success">
  <a class="btn-close">Close</a>
  <p></p>
</div>


	<div class="over-wrap" style={{height: "513px"}}>
	<table style={{display:'none'}}>

		<tbody><tr id="tempTr">
			<td id="createDate" class="align-L"></td>
			<td id="userId" class="align-L" style={{display:'none'}}></td>
			<td id="deposit">-</td>
			<td id="withdraw">-</td>
			<td id="balance"></td>
			<td id="remark"></td>
			<td>
				<spen id="from"></spen>
				<img class="fromto" src={Transparent}/>
				<spen id="to"></spen>
			</td>
		</tr>

		<tr id="noDataTempTr">
			<td class="no-data" colspan="6">
				<p>No Data</p>
			</td>
		</tr>
	</tbody>
    </table>

		<table id="table_log" class="table01">
			<tbody><tr>
				<th width="11%" class="align-L">Date/Time</th>
				<th width="13%" class="align-L" style={{display:'none'}}>UID</th>
				<th width="13%">Deposit</th>
				<th width="13%">Withdraw</th>
				<th width="13%">Balance</th>
				<th width="15%">Remark</th>
				<th width="">From/To</th>
			</tr>

			</tbody><tbody id="content">

         
      {currentPosts.map((item,index)=>{
            var obj1;
            var obj2;
            if(item.amount >= 0){
               obj1 = item.amount;
               obj2 = '-';
            }
            if(item.amount < 0){
               obj1 = '-';
               obj2 = Math.abs(item.amount);
            }
             var obj3 = item.balance;
        

        return(
          <tr id="tempTr" key = {index}>
			<td id="createDate" class="align-L">{item.time}</td>
			<td id="userId" class="align-L" style={{display:'none'}}>akshayddl</td>
			<td id="deposit" class="">{obj1}</td>
			<td id="withdraw" class="red">{item.amount < 0 ? '('+obj2+')': obj2}</td>
			<td id="balance">{obj3}</td>
			<td id="remark">{item.remark}</td>
			<td>
				<spen id="from">{item.fromAgent}</spen>
				<img class="fromto" src={Transparent}/>
				<spen id="to">{item.toAgent}</spen>
			</td>
		  </tr>
          )})}
         </tbody>
		</table>
	</div>

	
{allLog.length > 0 &&  <div className="pages">
    <Pagination
        prevPageText='prev'
        pageRangeDisplayed={4}
        activePage={currentPage}
        nextPageText='next'
        totalItemsCount={allLog.length}
        onChange={handlePageChange}
        itemsCountPerPage={postsPerPage}
        hideFirstLastPages
      />
     </div>}
    </div>


     {/*</body>*/}

    </div>
   )
 }