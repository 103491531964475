import React, { useState, useEffect } from 'react'
import Transparent from './images/transparent.gif'
import Loading from './images/loading40.gif'
import axios from 'axios';
import Cookies from 'universal-cookie';
// import Soccerpng from './images/12bet/soccer.png'
// import cricketpng from "./images/12bet/cricketBall.png"
// import Tennispng from "./images/12bet/tennispng.png"

import moment from 'moment'
import { toast } from 'react-toastify'
import MarketView from './MarketView'
import CricketMatchSock from './CricketMatchSock';
import SoccerMatchSock from './SoccerMatchSock';
import TennisMatchSock from './TennisMatchSock';
import DeclaredFancy from './DeclaredFancy'
import FancyBook from "./FancyBook";
import Fancydatashow from "./Fancydatashow"

toast.configure()


require('moment-timezone');

const cookies = new Cookies();

export default function RiskManagement(props) {

    const [marketClick, setmarketClick] = useState(-1)
    const [marketClick1, setmarketClick1] = useState(-1)
    const [marketClick2, setmarketClick2] = useState(-1)
    const [select, setselect] = useState(0)
    const [marketData, setmarketData] = useState({})
    const [bookData2, setbookData2] = useState({})
    const [bookData, setbookData] = useState({})
    const [cricketList, setcricketList] = useState([]);
    const [showDownline, setshowDownline] = useState(false);
    const [soccerList, setsoccerList] = useState([]);
    const [tennisList, settennisList] = useState([]);
    const [refresh, setrefresh] = useState(true);
    const [reload, setreload] = useState(true);
    const [eventIdset, seteventIdset] = useState('')
    const [eventId, seteventId] = useState('')
    const [runner1BackRate3, setrunner1BackRate3] = useState(' ');
    const [runner1BackSize1, setrunner1BackSize1] = useState(' ');
    const [runner1BackSize2, setrunner1BackSize2] = useState(' ');
    const [runner1BackSize3, setrunner1BackSize3] = useState(' ');
    const [runner1LayRate1, setrunner1LayRate1] = useState(' ');
    const [runner1LayRate2, setrunner1LayRate2] = useState(' ');
    const [runner1LayRate3, setrunner1LayRate3] = useState(' ');
    const [runner1LaySize1, setrunner1LaySize1] = useState(' ');
    const [runner1LaySize2, setrunner1LaySize2] = useState(' ');
    const [runner1LaySize3, setrunner1LaySize3] = useState(' ');
    const [runner1BackRate1, setrunner1BackRate1] = useState(' ');
    const [runner1BackRate2, setrunner1BackRate2] = useState(' ');
    const [runner2BackRate1, setrunner2BackRate1] = useState(' ');
    const [runner2BackRate2, setrunner2BackRate2] = useState(' ');
    const [runner2BackRate3, setrunner2BackRate3] = useState(' ');
    const [runner2BackSize1, setrunner2BackSize1] = useState(' ');
    const [runner2BackSize2, setrunner2BackSize2] = useState(' ');
    const [runner2BackSize3, setrunner2BackSize3] = useState(' ');
    const [runner2LayRate1, setrunner2LayRate1] = useState(' ');
    const [runner2LayRate2, setrunner2LayRate2] = useState(' ');
    const [runner2LayRate3, setrunner2LayRate3] = useState(' ');
    const [runner2LaySize1, setrunner2LaySize1] = useState(' ');
    const [runner2LaySize2, setrunner2LaySize2] = useState(' ');
    const [runner2LaySize3, setrunner2LaySize3] = useState(' ');


    const [tieBackRate1, settieBackRate1] = useState(' ');
    const [tieBackRate2, settieBackRate2] = useState(' ');
    const [tieBackRate3, settieBackRate3] = useState(' ');
    const [tieBackSize1, settieBackSize1] = useState(' ');
    const [tieBackSize2, settieBackSize2] = useState(' ');
    const [tieBackSize3, settieBackSize3] = useState(' ');
    const [tieLayRate1, settieLayRate1] = useState(' ');
    const [tieLayRate2, settieLayRate2] = useState(' ');
    const [tieLayRate3, settieLayRate3] = useState(' ');
    const [tieLaySize1, settieLaySize1] = useState(' ');
    const [tieLaySize2, settieLaySize2] = useState(' ');
    const [tieLaySize3, settieLaySize3] = useState(' ');
    const [fancyData, setfancyData] = useState([])
    const [marketBetStatus, setmarketBetStatus] = useState(0);
    const [popup, setpopup] = useState(null);
    const [marketpnl1, setmarketpnl1] = useState(0);
    const [marketpnl2, setmarketpnl2] = useState(0);
    const [marketpnl3, setmarketpnl3] = useState(0);
    const [bookpnl1, setbookpnl1] = useState(0);
    const [bookpnl2, setbookpnl2] = useState(0);
    const [bookpnl3, setbookpnl3] = useState(0);
    const [fancySelection, setfancySelection] = useState(null);
    const [fancyName, setfancyName] = useState('');
    const [marketIdnew, setmarketIdnew] = useState('')
    const [marketName, setmarketName] = useState('')


    const MarketClickOpen = (index) => {
        setmarketClick(!marketClick)

        if (marketClick === index) {
            setmarketClick(-1);
        }
        else {
            setmarketClick(index);
        }
    }



    const MarketClickOpen1 = (index) => {
        setmarketClick1(!marketClick1)

        if (marketClick1 === index) {
            setmarketClick1(-1);
        }
        else {
            setmarketClick1(index);
        }
    }

    const MarketClickOpen2 = (index) => {
        setmarketClick2(!marketClick2)

        if (marketClick2 === index) {
            setmarketClick2(-1);
        }
        else {
            setmarketClick2(index);
        }
    }


    useEffect(() => {
        getProfitLoss();

        const intervalId = setInterval(() => {
            getProfitLoss();
        }, 5000);


        return () => clearInterval(intervalId);

    }, [marketData, bookData2, props.eventId])



    useEffect(() => {


        if (!props.eventId) return;

        axios.post('https://waveapi.in/api/client/clientgetFullMarket', {

            eventId: props.eventId,

        })
            .then(result => {
                // console.log(result);

                if (result.data[0]) {
                    let obj = result.data[0];
                    setrunner1BackRate1(obj.runner1BackRate1);
                    setrunner1BackRate2(obj.runner1BackRate2);
                    setrunner1BackRate3(obj.runner1BackRate3);
                    setrunner1LayRate1(obj.runner1LayRate1);
                    setrunner1LayRate2(obj.runner1LayRate2);
                    setrunner1LayRate3(obj.runner1LayRate3);
                    setrunner2BackRate1(obj.runner2BackRate1);
                    setrunner2BackRate2(obj.runner2BackRate2);
                    setrunner2BackRate3(obj.runner2BackRate3);
                    setrunner2LayRate1(obj.runner2LayRate1);
                    setrunner2LayRate2(obj.runner2LayRate2);
                    setrunner2LayRate3(obj.runner2LayRate3);

                    if (obj.runnerId3) {
                        settieBackRate1(obj.tieBackRate1);
                        settieBackRate2(obj.tieBackRate2);
                        settieBackRate3(obj.tieBackRate3);
                        settieLayRate1(obj.tieLayRate1);
                        settieLayRate2(obj.tieLayRate2);
                        settieLayRate3(obj.tieLayRate3);

                    }
                    //   console.log(obj);

                    setmarketData(obj);
                }

            })
            .catch(e => {

            });


        axios.post('https://waveapi.in/api/client/clientgetBookMakerMarket', {

            eventId: props.eventId,

        })
            .then(result => {


                if (result.data[0]) {
                    let obj = result.data[0];
                    setbookData2(obj);
                }



            })
            .catch(e => {

            });
    }, []);



    const getProfitLoss = () => {
        var ssid = cookies.get('sid');

        if (!ssid) return;
        if (!props.eventId) return;

        if (marketData.marketId) {
            axios.post('https://waveapi.in/api/agent/newagentMarketExposure', {
                sid: ssid,
                eventId: props.eventId,
                marketId: marketData.marketId

            })
                .then(result => {

                    if (refresh) { setrefresh(false); }
                    if (result.status === 200) {

                        setmarketpnl1(result.data.teamA_total);
                        setmarketpnl2(result.data.teamB_total);
                        setmarketpnl3(result.data.draw_total);
                        // console.log(result.data);

                    }

                })
                .catch(e => {

                });
        }

        if (bookData2.marketId) {

            axios.post('https://waveapi.in/api/agent/newagentMarketExposure', {
                sid: ssid,
                eventId: props.eventId,
                marketId: bookData2.marketId

            })
                .then(result => {

                    if (refresh) { setrefresh(false); }
                    if (result.status === 200) {

                        setbookpnl1(result.data.teamA_total);
                        setbookpnl2(result.data.teamB_total);
                        setbookpnl3(result.data.draw_total);


                    }

                })
                .catch(e => {

                });

        }

    }



    // end runningmarketana
    useEffect(() => {

        var ssid = cookies.get('sid');
        if (!ssid) return;
        setrefresh(true);

        axios.post('https://waveapi.in/api/agent/riskManagementList', {
            sid: ssid,
            eventType: 4
        })
            .then(result => {
                setrefresh(false);
                var res = [];
                if (result.status === 200) {
                    for (let key in result.data) {

                        if (result.data.hasOwnProperty(key)) {
                            result.data[key].eventId = key;
                            res.push(result.data[key]);


                        }
                    }
                    setcricketList(res);

                }



            }

            ).catch(e => {
                //setIsError(true);
            });

        axios.post('https://waveapi.in/api/agent/riskManagementList', {
            sid: ssid,
            eventType: 1
        })
            .then(result => {

                if (result.status === 200) {
                    var res = [];

                    for (let key in result.data) {

                        if (result.data.hasOwnProperty(key)) {
                            result.data[key].eventId = key;
                            res.push(result.data[key]);

                        }
                    }
                    setsoccerList(res);

                }

            }

            ).catch(e => {
                //setIsError(true);
            });

        axios.post('https://waveapi.in/api/agent/riskManagementList', {
            sid: ssid,
            eventType: 2
        })
            .then(result => {

                if (result.status === 200) {
                    var res = [];

                    for (let key in result.data) {

                        if (result.data.hasOwnProperty(key)) {
                            result.data[key].eventId = key;
                            res.push(result.data[key]);

                        }
                    }
                    settennisList(res);

                }



            }

            ).catch(e => {
                //setIsError(true);
            });
    }, [reload])




    const changeDownlineLive = () => {
        setshowDownline(false);
        setpopup(null);
        setfancySelection(null);
        setfancyName('');

    }


    // const ViewClick = () => {
    //     window.open("/agent/riskManagement/riskDownLinePL", "", "width=1250, height=610")
    // }


    return (

        <React.Fragment>
            <div class="main_wrap">

                {refresh &&

                    <div className="loading-overlay" id="loading"><div className="loading-wrap" style={{ display: 'flex' }}><div className="loading"><div /><div /></div><p>Loading...</p></div></div>

                }


                {/* <!-- Message --> */}
                <div id="message" class="message-wrap success">

                    <a class="btn-close">Close</a>
                    <p></p>
                </div>

                {/* <!-- Report Table --> */}
                <div class="total_all">
                    <h2>Risk Management Summary
                    </h2>

                    <a class="btn_replay" id="refresh_Top_Racing" style={{ display: 'none' }}>
                        <img src={Transparent} /></a>
                </div>

                <div className="match-wrap1">
                    <div className="total_all">
                        <h2>Match Odd </h2>

                        <a className="btn_replay" onClick={() => { setreload(!reload); }} id="refresh_Match_Odds"><img src={Transparent} /></a>

                        <a className="btn_replay" id="downloadFile_Sportsbook" style={{ display: 'none', width: '70px' }}>Download</a>
                    </div>
                    <table className="table011 risk_matchodd">

                        <tbody>
                            <tr>
                                <th width="10%" className="align-L" rowSpan={2}>Sports</th>
                                <th width='8.1%' className="align-L" rowSpan={2}>Market Date</th>
                                <th className="align-L" rowSpan={2}>Event/Market Name</th>
                                <th width="21%" className="align-C border-l bg-yellow" colSpan={3}>Player P/L</th>
                                <th width="6%" className="align-C border-l" rowSpan={2}>Downline P/L</th>
                            </tr>
                            <tr>
                                <th width="7%" className="border-l bg-yellow" style={{ textAlign: 'center' }}>1</th>
                                <th width="7%" className="bg-yellow" style={{ textAlign: 'center' }}>X</th>
                                <th width="7%" className="bg-yellow" style={{ textAlign: 'center' }}>2</th>
                            </tr>
                        </tbody>



                        {cricketList.map((item, index) => {

                            return (
                                <tbody key={index} id="content_MATCH_ODDS_DRAW">
                                    <tr id="tempTr_MATCH_ODDS_DRAW" className="border-t">
                                        <td className="align-L" rowSpan={2} style={{ padding: '8px 10px' }} id="0_1">
                                            <a id="eventType" >Cricket</a></td>
                                        <td className="align-L border-l" rowSpan={2} id="1_1" style={{ padding: '8px 10px' }}>{item.date}</td>
                                        <td className="align-L border-l" style={{ padding: '8px 10px' }} id="linerh">

                                            <a onClick={() => { MarketClickOpen(index); seteventId(item.eventId) }} className={`${marketClick === index ? "btn open-odds" : "btn close-odds"}`} style={{}} name="oddsBtn_31135408">Open</a>

                                            <a id="marketPath">
                                                <strong id="eventName">{item.eventName}</strong>

                                            </a>
                                        </td>
                                        <td class="border-l" style={{ textAlign: 'center' }}><a className={`${item.teamA_total >= 0 ? "green" : "red"}`} id="selection_exposure_1">{item.teamA_total >= 0 ? parseFloat(item.teamA_total).toFixed(2) : '(' + parseFloat(Math.abs(item.teamA_total)).toFixed(2) + ')'}</a></td>
                                        <td style={{ textAlign: 'center' }}><a className={`${item.draw_total >= 0 ? "green" : "red"}`} id="selection_exposure_3">{item.draw_total >= 0 ? parseFloat(item.draw_total).toFixed(2) : '(' + parseFloat(Math.abs(item.draw_total)).toFixed(2) + ')'}</a></td>
                                        <td style={{ textAlign: 'center' }}><a id="selection_exposure_2"><span className={`${item.teamB_total >= 0 ? "green" : "red"}`}>{item.teamB_total >= 0 ? parseFloat(item.teamB_total).toFixed(2) : '(' + parseFloat(Math.abs(item.teamB_total)).toFixed(2) + ')'}</span></a></td>
                                        <td className="border-l">

                                            <a onClick={() => { setshowDownline(true); setpopup(1); seteventIdset(item.eventId); setmarketIdnew(item.marketId); setmarketName(item.eventName) }} className="btn" id="view">View</a></td>
                                    </tr>
                                    <tr id="expand_31135408" className="expanded" style={{ display: 'table-row' }}>
                                        <td className="border-l align-L" colSpan={4}>

                                            {/* <img className="expand-arrow" src={Transparent} /> */}
                                            {marketClick === index &&
                                                <CricketMatchSock setfancyName={setfancyName} setmarketIdnew={setmarketIdnew} setmarketName={setmarketName} setmarketIdnew={setmarketIdnew} seteventIdset={seteventIdset} setfancySelection={setfancySelection} setshowDownline={setshowDownline} setpopup={setpopup} marketpnl1={marketpnl1} marketpnl2={marketpnl2} marketpnl3={marketpnl3} popup={popup} isLoggedIn={props.isLoggedIn} tabMenu={props.tabMenu} changeDownlineLive={changeDownlineLive} showDownline={showDownline} eventId={eventId} />

                                          }
                                        </td>
                                        <td width={80} className="border-l" />
                                    </tr>
                                </tbody>
                            )
                        })}
                    </table>

                </div>

                <div className="match-wrap1">
                    <div className="total_all">
                        <h2>Fancy Bet </h2>

                        <a className="btn_replay" onClick={() => { setreload(!reload); }} id="refresh_Match_Odds"><img src={Transparent} /></a>

                        <a className="btn_replay" id="downloadFile_Sportsbook" style={{ display: 'none', width: '70px' }}>Download</a>
                    </div>
                    <table className="table011 risk_matchodd">

                        <tbody>
                            <tr>
                                <th width="10%" className="align-L" rowSpan={2}>Sports</th>
                                <th width='8.1%' className="align-L" rowSpan={2}>Market Date</th>
                                <th className="align-L" rowSpan={2}>Event/Market Name</th>
                                <th width="21%" className="align-C border-l bg-yellow" colSpan={3}>Player P/L</th>
                                <th width="6%" className="align-C border-l" rowSpan={2}>Downline P/L</th>
                            </tr>
                            <tr>
                                <th width="7%" className="border-l bg-yellow" style={{ textAlign: 'center' }}>1</th>
                                <th width="7%" className="bg-yellow" style={{ textAlign: 'center' }}>X</th>
                                <th width="7%" className="bg-yellow" style={{ textAlign: 'center' }}>2</th>
                            </tr>
                        </tbody>



                        {cricketList.map((item, index) => {

                            return (
                                <tbody key={index} id="content_MATCH_ODDS_DRAW">
                                    <tr id="tempTr_MATCH_ODDS_DRAW" className="border-t">
                                        <td className="align-L" rowSpan={2} style={{ padding: '8px 10px' }} id="0_1">
                                            <a id="eventType" >Cricket</a></td>
                                        <td className="align-L border-l" rowSpan={2} id="1_1" style={{ padding: '8px 10px' }}>{item.date}</td>
                                        <td className="align-L border-l" style={{ padding: '8px 10px' }} id="linerh">

                                            <a onClick={() => { MarketClickOpen2(index); seteventId(item.eventId) }} className={`${marketClick === index ? "btn open-odds" : "btn close-odds"}`} style={{}} name="oddsBtn_31135408">Open</a>

                                            <a id="marketPath">
                                                <strong id="eventName">{item.eventName}</strong>

                                            </a>
                                        </td>
                                        <td class="border-l" style={{ textAlign: 'center' }}><a className={`${item.teamA_total >= 0 ? "green" : "red"}`} id="selection_exposure_1">{item.teamA_total >= 0 ? parseFloat(item.teamA_total).toFixed(2) : '(' + parseFloat(Math.abs(item.teamA_total)).toFixed(2) + ')'}</a></td>
                                        <td style={{ textAlign: 'center' }}><a className={`${item.draw_total >= 0 ? "green" : "red"}`} id="selection_exposure_3">{item.draw_total >= 0 ? parseFloat(item.draw_total).toFixed(2) : '(' + parseFloat(Math.abs(item.draw_total)).toFixed(2) + ')'}</a></td>
                                        <td style={{ textAlign: 'center' }}><a id="selection_exposure_2"><span className={`${item.teamB_total >= 0 ? "green" : "red"}`}>{item.teamB_total >= 0 ? parseFloat(item.teamB_total).toFixed(2) : '(' + parseFloat(Math.abs(item.teamB_total)).toFixed(2) + ')'}</span></a></td>
                                        <td className="border-l">

                                            <a onClick={() => { setshowDownline(true); setpopup(1); seteventIdset(item.eventId); setmarketIdnew(item.marketId); setmarketName(item.eventName) }} className="btn" id="view">View</a></td>
                                    </tr>
                                    <tr id="expand_31135408" className="expanded" style={{ display: 'table-row' }}>
                                        <td className="border-l align-L" colSpan={4}>

                                            {/* <img className="expand-arrow" src={Transparent} /> */}
                                            {marketClick2 === index &&
                                                <Fancydatashow setfancyName={setfancyName} setmarketIdnew={setmarketIdnew} setmarketName={setmarketName} setmarketIdnew={setmarketIdnew} seteventIdset={seteventIdset} setfancySelection={setfancySelection} setshowDownline={setshowDownline} setpopup={setpopup} marketpnl1={marketpnl1} marketpnl2={marketpnl2} marketpnl3={marketpnl3} popup={popup} isLoggedIn={props.isLoggedIn} tabMenu={props.tabMenu} changeDownlineLive={changeDownlineLive} showDownline={showDownline} eventId={eventId} />

                                          }
                                        </td>
                                        <td width={80} className="border-l" />
                                    </tr>
                                </tbody>
                            )
                        })}
                    </table>

                </div>


                <div className="match-wrap1">
                    <div className="total_all">
                        <h2>Soccer</h2>
                        {/* <h2><img className="png-match" src={Soccerpng} /></h2> */}

                        <a className="btn_replay" onClick={() => { setreload(!reload); }} id="refresh_Match_Odds">
                            <img src={Transparent} /></a>

                        <a className="btn_replay" id="downloadFile_Sportsbook" style={{ display: 'none', width: '70px' }}>Download</a>
                    </div>
                    <table className="table011 risk_matchodd">
                        <tbody>
                            <tr>
                                <th width="10%" className="align-L" rowSpan={2}>Sports</th>
                                <th width='8.1%' className="align-L" rowSpan={2}>Market Date</th>
                                <th className="align-L" rowSpan={2}>Event/Market Name</th>
                                <th width="21%" className="align-C border-l bg-yellow" colSpan={3}>Player P/L</th>
                                <th width="6%" className="align-C border-l" rowSpan={2}>Downline P/L</th>
                            </tr>
                            <tr>
                                <th width="7%" className="border-l bg-yellow" style={{ textAlign: 'center' }}>1</th>
                                <th width="7%" className="bg-yellow" style={{ textAlign: 'center' }}>X</th>
                                <th width="7%" className="bg-yellow" style={{ textAlign: 'center' }}>2</th>
                            </tr>
                        </tbody>


                        {soccerList.map((item, index) => {
                            return (
                                <tbody key={index} id="content_MATCH_ODDS_DRAW">
                                    <tr id="tempTr_MATCH_ODDS_DRAW" className="border-t">
                                        <td className="align-L" rowSpan={2} style={{ padding: '8px 10px' }} id="0_1">
                                            <a id="eventType" >Soccer</a></td>
                                        <td className="align-L border-l" rowSpan={2} id="1_1" style={{ padding: '8px 10px' }}>{item.date}</td>
                                        <td className="align-L border-l" style={{ padding: '8px 10px' }} id="">

                                            <a onClick={() => { MarketClickOpen1(index); seteventId(item.eventId) }} className={`${marketClick1 === index ? "btn close-odds" : "btn open-odds"}`} style={{}} name="oddsBtn_31135408">Open</a>

                                            <a id="marketPath">
                                                <strong id="eventName">{item.eventName}</strong>

                                            </a>
                                        </td>
                                        <td class="border-l" style={{ textAlign: 'center' }}><a className={`${item.teamA_total >= 0 ? "green" : "red"}`} id="selection_exposure_1">{item.teamA_total >= 0 ? parseFloat(item.teamA_total).toFixed(2) : '(' + parseFloat(Math.abs(item.teamA_total)).toFixed(2) + ')'}</a></td>
                                        <td style={{ textAlign: 'center' }}><a className={`${item.draw_total >= 0 ? "green" : "red"}`} id="selection_exposure_3">{item.draw_total >= 0 ? parseFloat(item.draw_total).toFixed(2) : '(' + parseFloat(Math.abs(item.draw_total)).toFixed(2) + ')'}</a></td>
                                        <td style={{ textAlign: 'center' }}> <a id="selection_exposure_2"><span className={`${item.teamB_total >= 0 ? "green" : "red"}`}>{item.teamB_total >= 0 ? parseFloat(item.teamB_total).toFixed(2) : '(' + parseFloat(Math.abs(item.teamB_total)).toFixed(2) + ')'}</span></a></td>
                                        <td className="border-l">

                                            <a onClick={() => { setshowDownline(true); setpopup(1); seteventIdset(item.eventId); setmarketIdnew(item.marketId); setmarketName(item.eventName) }} className="btn" id="view">View</a></td>
                                    </tr>
                                    <tr id="expand_31135408" className="expanded" style={{ display: 'table-row' }}>
                                        <td className="border-l align-L" colSpan={4}>



                                            {marketClick1 === index &&


                                                <SoccerMatchSock popup={popup} eventId={eventId} changeDownlineLive={changeDownlineLive} showDownline={showDownline} />

                                            }
                                        </td>
                                        <td width={80} className="border-l" />
                                    </tr>
                                </tbody>

                            )
                        })}

                    </table>
                </div>


           
           
           
            </div>

            {showDownline === true && popup === 1 && <MarketView changeUpdatedStatus={props.changeUpdatedStatus} view={props.view} changeView={props.changeView} popAgent={props.popAgent} level={props.level} agentStack={props.agentStack} pushAgent={props.pushAgent} changeDownlineLive={changeDownlineLive} eventId={eventIdset} marketId={marketIdnew} tie={marketData.runnerId3} marketname={marketName + ' - Match Odds'} />}
            {showDownline === true && popup === 2 && <MarketView changeUpdatedStatus={props.changeUpdatedStatus} level={props.level} agentStack={props.agentStack} pushAgent={props.pushAgent} changeDownlineLive={changeDownlineLive} eventId={eventIdset} marketId={marketIdnew} tie={bookData2.runnerId3} marketname={marketName + ' - BookMaker'} />}
            {showDownline === true && popup === 3 && <DeclaredFancy changeDownlineLive={changeDownlineLive} eventId={eventIdset} marketname={marketData.marketName ? marketData.eventName + ' - Declared Fancy' : marketName + ' - Declared Fancy'} />}

            {showDownline === true && popup === 4 && <FancyBook setfancySelection={setfancySelection} setshowDownline={setshowDownline} setpopup={setpopup} setfancyName={setfancyName} changeDownlineLive={changeDownlineLive} eventId={eventId} selectionId={fancySelection} marketname={marketData.marketName ? fancyName : fancyName} />}
        </React.Fragment>
    )
}
