import React, { useState,useEffect } from 'react'
import {toast} from 'react-toastify'
import Cookies from 'universal-cookie';
import axios from 'axios';

const cookies = new Cookies();
toast.configure()

export default function CreditRef(props) {

    const [currentStatus,setcurrentStatus] = useState(props.currentStatus);
    const [newVal,setnewVal] = useState('');
    const [pass,setpass] = useState('');



	const updateCredit = ()=>{
		
        if(newVal == props.oldVal){
         toast.warn('Please enter a different value', {position:toast.POSITION.TOP_CENTER})
		 return;
        }
		else if(isNaN(parseFloat(newVal))){
			toast.warn('Please enter a valid number', {position:toast.POSITION.TOP_CENTER})
			return;
		}
        else if(pass === '' || pass === ' ' ){
         toast.warn('password can not be blank', {position:toast.POSITION.TOP_CENTER})
		 return;
        }
		
        var ssid = cookies.get('sid');
        if(!ssid) return;



		if(props.aorc == '1'){
         axios.post('https://waveapi.in/api/agent/updateCreditAgent',{
               sid:ssid,
               newValue:newVal,
               agentId:props.agentId,
               password:pass

              })
              .then(result => {
                  if(result.status === 200){
                     toast.success('Credit referenace updated successfully', {position:toast.POSITION.TOP_CENTER})
                     props.changeUpdatedStatus();
                     props.HandlePopup(3,false,{user:'',oldVal:'0',aorc:''})
  
                  }
				  else if(result.status === 207){
					toast.warn('Wrong Password!', {position:toast.POSITION.TOP_CENTER})

				  }
                  else{
                    toast.warn('Something went wrong', {position:toast.POSITION.TOP_CENTER})
                  }
         
                 }
                     
                ).catch(e => {
                  //setIsError(true);
              });
			}

			else if(props.aorc == '2'){
				axios.post('https://waveapi.in/api/agent/updateCreditClient',{
					sid:ssid,
					newValue:newVal,
					agentId:props.agentId,
					password:pass
	 
				   })
				   .then(result => {
					   if(result.status === 200){
						  toast.success('Credit referenace updated successfully', {position:toast.POSITION.TOP_CENTER})
						  props.changeUpdatedStatus();
						  props.HandlePopup(3,false,{user:'',oldVal:'0',aorc:''})
	   
					   }
					   else if(result.status === 207){
						 toast.warn('Wrong Password!', {position:toast.POSITION.TOP_CENTER})
	 
					   }
					   else{
						 toast.warn('Something went wrong', {position:toast.POSITION.TOP_CENTER})
					   }
			  
					  }
						  
					 ).catch(e => {
					   //setIsError(true);
				   });

			  }
            }


	
    return (
        <React.Fragment>
    <div id="creditReferenceModel" class="pop_bg" style={{top:'0',display: 'block'}}>
	<div class="pop_refer">
		<a class="close_pop" href="javascript: void(0)" onClick={()=>{props.HandlePopup(3,false,{user:'',oldVal:'0',aorc:''})}}>close_pop</a>
		<h3>Credit Reference Edit</h3>

		<div class="wrap-refer_edit">
			<dl>
				<dt>Current</dt>
				<dd>
					{/*<a class="btn" id="creditReferenceLog" href="javascript: void(0)">Log</a>*/}
					<strong id="creditReference">{props.oldVal}</strong>
				</dd>
			</dl>

			<dl>
				<dt>New</dt>
				<dd><input type="text" id="newCreditReference" onChange = {(e)=>{setnewVal(e.target.value);}} placeholder="Enter"/></dd>
			</dl>

			<dl>
				<dt>Password</dt>
				<dd><input id="changeCreditReferencePassword" type="password" onChange = {(e)=>{setpass(e.target.value);}} placeholder="Enter"/></dd>
			</dl>
		</div>
		<ul class="btn-wrap" style ={{marginLeft:'145px'}}>
			<li><a id="changeCreditReferenceBtn" class="btn-send"  onClick = {()=>{updateCredit();}}>Submit</a></li>
		</ul>
	  </div>
    </div>
        </React.Fragment>
    )
}
