import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from "react-toastify";

const cookies = new Cookies();


export default function Messagepopup(props) {
    const [date, setDate] = useState(moment().toDate());
    const [message, setmessage] = useState("");

    const changeStartDate = (val) => {
        setDate(val);
    }

    var start = "";

    start = moment().format('ll');


    const Addnews = () => {

        var ssid = cookies.get('sid');
        axios.post('https://waveapi.in/api/agent/addfeednews', {
            sid: ssid,
            date: date,
            message: message


        }).then(result => {
            if (result.status === 200) {
                toast.success(" News Add successfully.", {
                    position: toast.POSITION.TOP_CENTER,
                });
                props.changeUpdatedStatus();
                props.ClickToOpen(true);
            }

            else {
                toast.warn(" Opps, there is  something woring!", {
                    position: toast.POSITION.TOP_CENTER,
                });

            }
        }).catch(e => { });


    }


    return (
        <React.Fragment>
            <div id="createModal" class="pop_bg" style={{ top: '0px', display: 'block' }}>
                <div class="pop_box">

                    <div style={{ display: 'flex' }}>
                        <a class="close_pop" onClick={() => props.ClickToOpen(true)}>close_pop</a>
                        <h3>Notification</h3>

                        <dd style={{ marginLeft: '15px' }}>
                            <DatePicker
                                selectsStart
                                dateFormat="yyyy-MM-dd"
                                selected={date}
                                placeholderText="YYYY-MM-DD"
                                className="cal-input"
                                onChange={changeStartDate}
                                style={{ transform: 'translate3d(100px, 76px, 0px)' }}
                            />
                        </dd>
                    </div>
                    <ul class="half_box add-member-box">
                        <li class="add_account_box">
                            <dl class="">
                                <dd style={{padding:'0'}}>
                                    <textarea rows="4" cols="50" value={message} onChange={(e) => { setmessage(e.target.value) }} name="comment" form="usrform" style={{ border: '1px solid #000', width: '380px', height: '100%' }}></textarea>
                                </dd>
                            </dl>
                        </li>
                    </ul>

                    <div class="btn_box"><a id="createBtn" class="btn-send" onClick={() => { Addnews() }} >Add</a></div>
                </div>
            </div>
        </React.Fragment>
    )
}