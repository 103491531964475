import React,{ useState } from 'react'
import Transparent from './images/transparent.gif'
import { Link} from "react-router-dom";
import Cookies from 'universal-cookie';
import mainlogopic from './images/logomain123.png'

const cookies = new Cookies();


export default function Header(props) {
	const [loading, setloading] = useState(false);

	const handlerefresh = ()=>{
		setloading(true);
		props.checkUpdateBalance(); 
       setTimeout(()=>{setloading(false);},1000);
	}

	// console.log(props.balance,'dsfgh');
    return (
   <React.Fragment>
   <div class="top">
   <div class="header">
    {/* <h1><a href="/agents" style={{width:'180px',marginTop:'3px',backgroundRepeat:' no-repeat',backgroundSize:' contain'}}>BSF1010</a></h1> */}
	<img src={mainlogopic} class="logoitempic"/>
    <ul class="account-wrap">
	 <li><span>{props.level}</span><strong>{props.user}</strong></li>
	  {/* <!-- single balance 加 no-multi --> */}
      <li class="main-wallet no-multi">
      	{/* <!-- open--> */}
      	<a class="a-wallet">
			  {loading === false &&
      		<ul>
      			<li>
      				<span>Main</span>
      				<strong id="mainBalance">PTH {parseFloat(props.balance).toFixed(2)}</strong>
      			</li>
      		</ul>
			  }
			{loading === true &&
      		<p class="loading-bar" id="menuRefreshLoading">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</p>}
      	</a>

		<a id="topRefresh" class="a-refresh" onClick={()=>{handlerefresh()}} style={{cursor:'pointer'}}><img src={Transparent}/></a>
      </li>
    </ul>
  </div>

  {/* <!-- Menu Wrap --> */}
  <div class="menu-wrap">
    <div class="main_wrap">
      <ul class="menu">
		  {/* {	props.level === "ML" &&
		    <li><a href="/">EventManagement
	      </a></li>} */}


	      <li><Link to ="/agents" onClick={()=>{var url = window.location.href;var para = url.split( '/' );var eventT = para[3];if(eventT === 'agents')window.location.reload();}} id="menu_downline_list" className={`${(props.tabMenu===1 )? "select": "null"}`} >Downline List
	      </Link></li>
	      
	      <li>
		      <Link to="/Accounts" id="menu_my_account" className={`${(props.tabMenu===2 || props.tabMenu===9 || props.tabMenu===10 || props.tabMenu===11 || props.tabMenu===12)? "select": "null"}`}>Accounts
		      </Link>
		  </li>

	      <li>
			  <a id="menu_my_report"  className={`${(props.tabMenu===3 || props.tabMenu===4)? "menu-drop select": "menu-drop" }`}> Report
	      </a>
		      <ul style={{background:"#983838"}}>
			      
			      <li><a class="dropcolor" href="/ag/report/downLineProfitLoss" >Profit/Loss Report by Downline
			         </a>
				  </li>
			      <li><a class="dropcolor" href="/ag/report/marketProfitLoss" >Profit/Loss Report by Market
			        </a>
				  </li>  
				  {/* <li><a class="dropcolor" href="/casinoProfitLoss" >Profit/Loss Report of Casino
			        </a>
				  </li>       */}
		      </ul>
	      </li>
	      <li><Link to="/ag/betList/" id="menu_bet_list" className={`${(props.tabMenu===5 || props.tabMenu===21)? "select": "null"}`} >BetList
	      </Link></li>
		  {	props.level === "ML" &&	
	      <li><Link to="/agent/betList/voidbetlist" id="menu_bet_list" className={`${(props.tabMenu===22 || props.tabMenu===26)? "select": "null"}`} >BetListLive
	      </Link></li>}
		  {	props.level != "ML" &&	
	      <li><Link to="/livebets" id="menu_bet_list_live" className={`${(props.tabMenu===6)? "select": "null"}`} >LiveBets
	      </Link></li>}
	      <li><Link to="/ag/Manager" id="menu_risk_management" className={`${(props.tabMenu===7)? "select": "null"}`}>Manager
	      </Link></li>
	     {props.level !== 'MA' && <li>
			 <Link to="/banking" id="menu_banking" className={`${(props.tabMenu===8)? "select": "null"}`}>Banking
	      </Link></li>}
		  {props.level === 'MA' &&	  <li><Link to="/playerBanking" id="menu_banking" className={`${(props.tabMenu===15)? "select": "null"}`}>Banking
	      </Link></li>}
		  {/* {	props.level === "ML" &&	
			  <li><Link to="/alert" id="menu_banking" className={`${(props.tabMenu===22)? "select": "null"}`}>Alert
	      </Link></li>} */}
		  {	props.level === "ML" &&
		  <li><Link to="/notification" id="menu_banking" className={`${(props.tabMenu===25)? "select": "null"}`}>Notification
	      </Link></li>}
		  {/* <li>
			  <Link to="" id="menu_casinocontrol" target="_blank" className={`${(props.tabMenu===23)? "select": "null"}`}>Casino Control
	      </Link></li> */}
		 {/* <li><a id="menu_dashboard" class="">Dashboard
		  </a></li>*/}
        <li class="logout">
          <a onClick = {()=>{cookies.remove('sid',  { path: '/' });setTimeout(()=>{window.location.href = '/';},200);}} id="logout">Logout<img src={Transparent}/></a>
        </li>
        <li class="time_zone"><span>Time Zone :</span> GMT+5:30</li>
      </ul>
    </div>
  </div>
</div>
        </React.Fragment>
    )
}
