import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import moment from 'moment';
import axios from 'axios';
import {toast} from 'react-toastify'
import {Link} from 'react-router-dom'
import Transparent from './images/transparent.gif'
import Pagination from 'react-js-pagination';
import DatePicker from "react-datepicker";
import Loading from './images/loading40.gif'
import "react-datepicker/dist/react-datepicker.css";

const cookies = new Cookies();
window.bet_day = 3;
window.betlistView = 1;
toast.configure()

export default function Voidbetlist() {

   const [MatchList,setMatchList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage] = useState(30);
   const [EventType,seteventType] = useState('100');
   const [betStatus,setbetStatus] = useState('2');
   const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
   const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
   const [startDate, setStartDate] = useState(moment().toDate());
   const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
   const [refresh,setrefresh] = useState(false);
   const indexOfLastPost = currentPage * postsPerPage;
   const indexOfFirstPost = indexOfLastPost - postsPerPage;
   const currentPosts = MatchList.slice(indexOfFirstPost, indexOfLastPost);

   

   useEffect(() => {
    var ssid = cookies.get('sid');
        if(!ssid) return;
        setrefresh(true);
    axios.post('https://waveapi.in/api/agent/DeclareddMatchList',{
        sid:ssid,
        eventType:EventType
       })
       .then(result => {
          setrefresh(false);
         setMatchList(result.data); 
         if(result.status === 200){
            result.data.map(item=>{
                item.marketStartTime = moment.tz(item.marketStartTime,'YYYY-MM-DD HH:mm:ss','Asia/Kolkata').local().format('YYYY-MM-DD HH:mm:ss');
                return item;
              }) 
              result.data.sort(function (a, b) {
                return moment(a.marketStartTime) - moment(b.marketStartTime);
              });
              var obj = [];
              result.data.map((item)=>{
                 
                  if(moment() >= moment(item.marketStartTime)){
                    obj.push(item);
                  }
              });
              setMatchList(result.data);
          
        //     setInplay(result.data);    
           }
          }
              
        ).catch(e => {
           //setIsError(true);
       });
  }, [EventType])
   

  const handlePageChange = ( pageNumber ) => { 
    setCurrentPage( pageNumber )
 };


      const changeBetStatus = (e)=>{
         var selectBox = document.getElementById("betStatus");
         var selectedValue = selectBox.options[selectBox.selectedIndex].value;
           
           if(selectedValue == 2){
            setbetStatus('2');
           }
           else if(selectedValue == 3){
             setbetStatus('3');
           }
      
          }	
          
      
          const changeEvent = (e)=>{
            seteventType(e.target.value);
        }	
      
  console.log(EventType);

  return (
  <React.Fragment>
   <div class="full-wrap" style={{height: 'calc(100%)'}}>
    {/* <!-- Center Column --> */}
    <div class="over-wrap" style={MatchList.length === 0 ? {height: 'inherit'} : {height: 'fit-content'}} >
      {/* <!-- Loading Wrap --> */}
      {refresh && 

<div className="loading-overlay" id="loading"><div className="loading-wrap" style={{display: 'flex'}}><div className="loading"><div /><div /></div><p>Loading...</p></div></div>
          
          }
      {/* <!-- Message --> */}
      <div id="message" class="message-wrap success">
         <a class="btn-close">Close</a>
         <p></p>
      </div>
     
      <h2>Bet List</h2>

      <ul class="input-list">
                  <li id="changeEventType" onChange = {(e)=>{changeEvent(e);}}>
                     <input type="radio" name="events" selected={`${EventType == 100 ? "selected":""}`} value="100"/>All
                     <input type="radio" name="events" id="events_4" selected={`${EventType == 4 ? "selected":""}`} value="4"/>Cricket
                     <input type="radio" name="events" id="events_1" selected={`${EventType == 1 ? "selected":""}`} value="1"/>Soccer
                     <input type="radio" name="events" id="events_2" selected={`${EventType == 2 ? "selected":""}`} value="2"/>Tennis
                  </li>
               </ul>

    
    
      
       <div id="reportDiv" className="over-wrap" style={{maxHeight: 'calc(100% - 32px - 93px)'}}>
    <table id="table_DL" className="table01 table-pt" style={{}}>
        <tbody>
            <tr id="head">
            <th width="15%" className="align-L" >Date/time</th>
                <th id="header_eventName" width className="align-L">UID</th>
                <th width="15%" className>eventId</th>
                <th width="15%" className>MarketId</th>
                
                <th id="header_profitLoss_downLine" width="15%" className>Total bets</th>
           
                {/* <th id="header_tax_agent_2" width="15%" className>Super Comm.</th>
                <th width="15%" className>Upline P/L</th> */}
            </tr>
        </tbody>
        <tbody id="content">
        {currentPosts.map(function(item,index){
            
        return (

            <tr id="main_1882245_0">
                  <td id="_profitLoss" className="align-L"><span >{item.marketStartTime}</span></td>
                <td className="align-L">
                    {/* <a id="_byMarket" href="javascript:void(0);" className="expand-close"/> */}
                    <a> 
                        {item.sportId === "4" &&
                    <span id="_eventType">Cricket </span>}
                      {item.sportId === "1" &&
                    <span id="_eventType">Soccer </span>  }
                      {item.sportId === "2" &&
                        <span id="_eventType">Tennis </span>}
            <img className="fromto" src="/images/transparent.gif" />
            <strong id="_eventName"><a href={`/agent/voidbetlist/UnderVoidbetList/${item.eventId}`}>{item.eventName}</a></strong></a>
                </td>
                <td id="_stake">{item.eventId}</td>
                <td id="_profitLoss"><span >{item.marketId}</span></td>
              
                <td id="_profitLossDownLine"><span >{item.Total_bet}</span></td>
                {/* <td id="_tax2"> 0.00</td>
                <td id="_profitLossUpLine"> 0.00</td> */}
            </tr>
      
      )})} 
{/*      
            <tr id="tempTotalTr" className="total">
                <td className="align-L">Total</td>
                <td id="_totalStake"> 00.00</td>
                <td id="_totalProfitLoss"><span className="red">( 00.00)</span></td>
                <td id="_totalProfitLossDownLine"><span className="red">( 00.00)</span></td>
             
            </tr> */}
        </tbody>
    </table>

    {MatchList.length > 0 &&  <div className="pages">
        <Pagination
        prevPageText='prev'
        pageRangeDisplayed={3}
        activePage={currentPage}
        nextPageText='next'
        totalItemsCount={MatchList.length}
        onChange={handlePageChange}
        itemsCountPerPage={postsPerPage}
        hideFirstLastPages
      />
      </div>}

</div>    </div>
      </div>
      
      </React.Fragment>
      )
   }
