import React, { useState,useEffect } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import { Link} from "react-router-dom";
import Loading from './images/loading40.gif'
import Pagination from 'react-js-pagination';


const cookies = new Cookies();

export default function ActivityLog(props) {

   

    const [accountStatement, setaccountStatement ] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [refresh,setrefresh] = useState(true);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = accountStatement.slice(indexOfFirstPost, indexOfLastPost);

   

    useEffect(() => {
        var ssid = cookies.get('sid');
        if(!ssid) return;
        axios.post('https://waveapi.in/api/agent/myactivitylog',{
            sid:ssid,
           }).then(result => {
            setaccountStatement(result.data)
            if(refresh){

                setrefresh(false);
            }
     }
   ).catch(e => {
     //setIsError(true);
   });   
    }, [])

   
  const handlePageChange = ( pageNumber ) => {
      
      setCurrentPage( pageNumber )
   };


    return (
        <React.Fragment>
            <div class="main_wrap">
	{/* <!-- agent path --> */}
<div class="agent_path">
    <ul id="agentPath" class="agent_path-L">
        
  
        
        <li id="path5" class="last_li">
        <a href="/agents">
                <span class="lv_1">
                  ss
                </span>
                <strong>{props.user}</strong>
            </a>
        </li>
        
        <li id="path4" class="" style={{display:'none'}}>
            <a href="javascript: void(0);">
                <span class="lv_2">
                    SUP
                </span>
                <strong></strong>
            </a>
        </li>
        
        <li id="path3" class="" style={{display:'none'}}>
            <a href="javascript: void(0);">
                <span class="lv_3">
                    MA
                </span>
                <strong></strong>
            </a>
        </li>
        
        <li id="path0" class="" style={{display:'none'}}>
            <a href="javascript: void(0);">
                <span class="lv_4">
                    PL
                </span>
                <strong></strong>
            </a>
        </li>
        
        <ul class="account_pop" id="accountPop">
            <li id="popTmp" style={{display:'none'}}>
                <a href=""></a>
            </li>
        </ul>
    </ul>
</div>
	{/* <!-- Agent Left Column --> */}
<div class="col-left">

    {/* <!-- Sub Menu and Path --> */}
    <div class="sub_path">
        {/* <!-- Sub Menu --> */}
        <ul class="menu-list">
        <li class="class">Position</li>
            <li><Link to="/MyAccount/accountCashStatement" id="accountStatement" >Account Statement</Link></li>
            {/* <li><Link to="/MyAccount/accountSummary" id="accountSummary" >Account Summary</Link></li> */}
            {/* <li><Link to="/MyAccount/transferredLog" id="transferredLog" >Transferred Log</Link></li> */}
            <li class="class">Account Details</li>
            <li><Link to="/MyAccount/profile" id="profile" >Profile</Link></li>
            <li><Link to="/Accounts/activityLog" id="activityLog" class="select">Activity Log</Link></li>
        </ul>
    </div>
</div>
	{/* <!-- Center Column --> */}
{/* <!-- Center Column --> */}
<div id="report" class="col-center report">
{/* <!-- Loading Wrap --> */}
<div id="loading" class="loading-wrap" style={{display:'none'}}>
  <ul class="loading">
    <li><img src={Loading}/></li>
    <li>Loading...</li>
  </ul>
</div>

{/* <!-- Message --> */}
<div id="message" class="message-wrap success">
  <a class="btn-close">Close</a>
  <p></p>
</div>
	<h2>Activity Log
	</h2>
	<table style={{display:'none'}}>
		<tbody><tr id="tempTr">
			<td class="align-L" id="loginDate"></td>
			<td class="align-L" id="loginMessage"><span ></span></td>
			<td id="ipAddress"></td>
			<td id="isp"></td>
			<td id="location"></td>
		</tr>
	</tbody></table>

	{/* <!-- Report Table --> */}
    <table className="table01">
    <tbody>
        <tr>
            <th width="15%" className="align-L">Login Date &amp; Time
            </th>
            <th width="15%" className="align-L">Login Status
            </th>
            <th width="12%">IP Address
            </th>
            <th width="28%">ISP
            </th>
            <th width>City/State/Country
            </th>
            <th width>User Agent Type
            </th>
        </tr>
    </tbody>
    <tbody id="content">
    {currentPosts.map(function(item,index){     
      return(
        <tr id="tempTr">
            <td className="align-L" id="loginDate">{item.loginDateTime}</td>
            <td className="align-L green" id="loginMessage">{item.status}</td>
            <td id="ipAddress">{item.ipAdress}</td>
            <td id="isp">{item.deviceInfo}</td>
            <td id="location">{item.remark}</td>
            <td id="userAgentType">{}</td>
        </tr>
        )})} 

    </tbody>

</table>
	{/* <!-- Pages List --> */}
    {accountStatement.length > 0 &&  <div className="pages">
        <Pagination
        prevPageText='prev'
        pageRangeDisplayed={3}
        activePage={currentPage}
        nextPageText='next'
        totalItemsCount={accountStatement.length}
        onChange={handlePageChange}
        itemsCountPerPage={postsPerPage}
        hideFirstLastPages
      />
      </div>}

</div>
</div>
        </React.Fragment>
    )
}
